import { atom } from "recoil";

export interface IRentalProps {
  registerType: "selfDriver" | "long_term_rental";
  data: {
    stepOne: {
      license_plate: string; // Biển số xe (license_plate)
      manufacture: string; // Hãng xe (manufacture)
      model: string; // Mẫu xe (model)
      color: string; // Màu sắc (color)
      year_of_manufacture: number; // Năm sản xuất (year_of_manufacture)
      seats: number; // Số ghế (seats)
      transmission: "automatic" | "manual"; // Hộp số (transmission)
      fuel_type: "gasoline" | "diesel" | "electric"; // Loại nhiên liệu (fuel_type)
      features: string[]; // Tính năng của xe (features)
      kilometers_runned: number; // Số km đã chạy (kilometers_runned)
      fuel_consumption: number; // Mức tiêu thụ nhiên liệu (fuel_consumption)
      title: string;
      slug: string;
      moreInfo: {
        address: {
          address: string;
          more?: string;
          ward: string;
          place_id: string | null;
          longitude: number | null;
          latitude: number | null;
        }; // Địa chỉ (address)
        description: string | null; // Mô tả xe (description)
        price: number | null;
        tax: number | null; // Thuế (sales_tax_type - có thể cần thêm logic để chuyển đổi từ exclusive hoặc inclusive sang số)
      };
    };
    stepTwo: {
      front_image: File | null; // Ảnh phía trước (front_image)
      back_image: File | null; // Ảnh phía sau (back_image)
      left_image: File | null; // Ảnh bên trái (left_image)
      right_image: File | null; // Ảnh bên phải (right_image)
    };
    stepThree: {
      registration_front_image: File | null; // Ảnh đăng ký mặt trước (registration_front_image)
      registration_back_image: File | null; // Ảnh đăng ký mặt sau (registration_back_image)
      vehicle_registration: File | null; // giấy đăng kiểm xe
      insurance_image: File | null; // ��nh bảo hiểm mặt trước (insurance_front_image)
    };
    stepFour: {
      sales_price: number; // Giá (sales_price, purchase_price có thể không có giá trị)
      rangPrice: [number, number];
      driverService: {
        is_chauffeur_driven: boolean; // Dịch vụ tài xế (is_chauffeur_driven)
        value: number; // Giá trị dịch vụ tài xế (có thể là một giá trị khác cần thêm)
      };
      // discount: {
      //   active: boolean; // Giảm giá (có thể là một cờ để chỉ ra giảm giá có hoạt động hay không)
      //   value: number; // Giá trị giảm giá (cần thêm logic nếu có)
      // };

      // service: {
      //   active: boolean;
      //   value: number;
      // };
      is_chauffer_driver: boolean;
      is_delivery_to_renter: boolean;
      is_longterm_rental: boolean;
    };
  };
}

export const initialValue: IRentalProps = {
  registerType: "selfDriver",
  data: {
    stepOne: {
      license_plate: "",
      manufacture: "",
      model: "",
      color: "",
      year_of_manufacture: 0,
      seats: 0,
      transmission: "automatic",
      fuel_type: "gasoline",
      features: [],
      kilometers_runned: null,
      fuel_consumption: 0,
      title: "",
      slug: "",
      moreInfo: {
        address: {
          address: "",
          longitude: null,
          latitude: null,
          place_id: null,
          more: "",
          ward: "",
        },
        description: null,
        tax: null,
        price: null,
      },
    },
    stepTwo: {
      front_image: null,
      back_image: null,
      left_image: null,
      right_image: null,
    },
    stepThree: {
      registration_front_image: null,
      registration_back_image: null,
      vehicle_registration: null,
      insurance_image: null,
    },
    stepFour: {
      rangPrice: [0, 0],
      sales_price: 0,
      driverService: {
        is_chauffeur_driven: false,
        value: 0,
      },

      is_chauffer_driver: false,
      is_delivery_to_renter: false,
      is_longterm_rental: false,

      // discount: {
      //   active: false,
      //   value: 0,
      // },

      // service: {
      //   active: false,
      //   value: 0,
      // },
    },
  },
};

export const rentalAtom = atom<IRentalProps>({
  key: "RENTAL",
  default: initialValue,
});

export const rentalStepAtom = atom<any>({
  key: "RENTAL_STEP",
  default: 1,
});
