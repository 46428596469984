import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import { yupResolver } from "@hookform/resolvers/yup";
import { schemaRegister } from "schema/schemaRegister";
import { signUpWebApi } from "services/api/authApi";
import { showCustomToast } from "shared/toast";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Input from "shared/Input/Input";

import facebookSvg from "images/Facebook.svg";
import googleSvg from "images/Google.svg";
import zaloSvg from "images/zaloLogo.svg";

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Tiếp tục với Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Tiếp tục với Google",
    href: "#",
    icon: googleSvg,
  },
  {
    name: "Tiếp tục với Zalo",
    href: "#",
    icon: zaloSvg,
    key: "zalo",
  },
];

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowPass, setIsShowPass] = useState({
    password: false,
    confirmPassword: false,
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schemaRegister),
    defaultValues: {},
  });

  const handleGoogleLogin = () => {
    // TODO: Implement Google login
  };
  const handleFacebookLogin = () => {
    // TODO: Implement Facebook login
  };

  const onSubmit = async (data) => {
    if (data.password !== data.confirmPassword) {
      setError("confirmPassword", {
        message: "Mật khẩu và nhập lại mật khẩu phải giống nhau",
        type: "error",
      });
      return;
    }

    if (data?.phone?.startsWith("0")) {
      data.phone = "84" + data.phone.slice(1);
    } else data.phone = "84" + data.phone;

    setIsLoading(true);

    try {
      await signUpWebApi({
        ...data,
        company_id: process.env.REACT_APP_ID_COMPANY,
      }).then(() => {
        showCustomToast({
          type: 1,
          message: "Đăng ký thành công",
        });
      });
      navigate("/login");
    } catch (error) {
      const err = error?.error?.details;
      if (err) {
        Object.entries(err).forEach(([field, messages]) => {
          setError(field, {
            message: messages[0],
            type: "manual",
          });
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`nc-PageSignUp relative ${className}`}
      data-nc-id="PageSignUp"
    >
      <Helmet>
        <title>3GO | Đăng ký</title>
        <meta
          name="description"
          content="Đăng ký tài khoản trên nền tảng 3GO để bắt đầu quản lý và sử dụng dịch vụ cho thuê xe của chúng tôi."
        />
        <meta property="og:title" content="3GO || Đăng ký" />
        <meta
          property="og:description"
          content="Đăng ký tài khoản trên nền tảng 3GO để bắt đầu quản lý và sử dụng dịch vụ cho thuê xe của chúng tôi."
        />
        <meta
          property="og:image"
          content="https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png"
        />
        <meta property="og:url" content={window.location.href + "/signup"} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="3GO || Đăng ký" />
        <meta
          name="twitter:description"
          content="Đăng ký tài khoản trên nền tảng 3GO để bắt đầu quản lý và sử dụng dịch vụ cho thuê xe của chúng tôi."
        />
        <meta
          name="twitter:image"
          content="https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png"
        />
      </Helmet>

      <BgGlassmorphism />

      <div className="container mb-24 lg:mb-32 relative">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Đăng ký
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0 w-[20px]"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              hoặc
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(onSubmit)();
            }}
            className="grid grid-cols-1 gap-6"
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Họ và Tên
              </span>
              <Input
                type="text"
                placeholder="Họ và Tên"
                className="mt-1 placeholder:text-transparent focus:placeholder:text-neutral-400 border border-neutral-300 focus:border-primary-500 transition-all placeholder:duration-300 ease-in-out transform"
                {...register("name")}
                error={errors.name?.message}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Địa chỉ email
              </span>
              <Input
                className="mt-1 placeholder:text-transparent focus:placeholder:text-neutral-400 border border-neutral-300 focus:border-primary-500 transition-all placeholder:duration-300 ease-in-out transform"
                type="email"
                placeholder="example@example.com"
                {...register("email")}
                error={errors.email?.message}
              />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Số điện thoại
              </span>
              <Input
                type="text"
                placeholder="Nhập số điện thoại"
                className="mt-1 placeholder:text-transparent focus:placeholder:text-neutral-400 border border-neutral-300 focus:border-primary-500 transition-all placeholder:duration-300 ease-in-out transform"
                {...register("phone")}
                error={errors.phone?.message}
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Mật khẩu
              </span>
              <Input
                type={!isShowPass.password && "password"}
                className="mt-1"
                {...register("password")}
                error={errors.password?.message}
                icon={
                  <div
                    onClick={() =>
                      setIsShowPass((prev) => ({
                        ...prev,
                        password: !prev.password,
                      }))
                    }
                    className="cursor-pointer relative"
                  >
                    {isShowPass.password ? (
                      <EyeIcon className="w-5 h-5 z-10 relative" />
                    ) : (
                      <EyeSlashIcon className="w-5 h-5 z-10 relative" />
                    )}
                  </div>
                }
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Nhập lại mật khẩu
              </span>
              <Input
                type={!isShowPass.confirmPassword && "password"}
                className="mt-1"
                {...register("confirmPassword")}
                error={errors.confirmPassword?.message}
                icon={
                  <div
                    onClick={() =>
                      setIsShowPass((prev) => ({
                        ...prev,
                        confirmPassword: !prev.password,
                      }))
                    }
                    className="cursor-pointer"
                  >
                    {isShowPass.confirmPassword ? (
                      <EyeIcon className="w-5 h-5" />
                    ) : (
                      <EyeSlashIcon className="w-5 h-5" />
                    )}
                  </div>
                }
              />
            </label>
            <ButtonPrimary loading={isLoading} type="submit">
              Đăng ký
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Đã có tài khoản? {` `}
            <Link to="/login" className="text-colorPrimary font-bold">
              Đăng nhập
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
