import { Dialog, Listbox, Transition } from "@headlessui/react";
import {
  filterFuel,
  filterPrice,
  filterSeats,
  filterTransmission,
} from "data/search";
import Slider from "rc-slider";
import React, { Fragment, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Radio from "./Radio";
import { useFilter } from "atom/filter/useFilter";
import { useCategory } from "atom/category/useCategory";
import { formatCurrency } from "utils/formatCurrency";
import {
  AdjustmentsVerticalIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import { CheckIcon } from "images/svg";
import { usePlace } from "atom/place/usePlace";
import { initialFilter } from "atom/filter/filter";

interface IModalFilterMobileProps {
  setCars?: any;
  currentTab?: string;
  setCurrentTab?: any;
  setIsFilter?: any;
}

const ModalFilterMobile = (props: IModalFilterMobileProps) => {
  const { setCars, currentTab, setCurrentTab, setIsFilter } = props;
  const { filter, setFilter, resetFilter, getQuery, requestFilterCar } =
    useFilter();
  const { place } = usePlace();
  const { serviceCategory } = useCategory();

  const [isShow, setIsShow] = useState(false);

  const handleResetFilter = async () => {
    resetFilter();
    if (currentTab && currentTab === "all") {
      await requestFilterCar
        .runAsync(getQuery(initialFilter))
        .then((res: any) => setCars(res?.data));
    }
    setFilter(initialFilter);
    setIsShow(false);
    setIsFilter && setIsFilter(false);
  };

  const handleFilter = async () => {
    setCurrentTab && setCurrentTab("all");
    try {
      const query = getQuery({
        rangePrice: filter.rangePrice,
        rangeYear: filter.rangeYear,
        orderBy: filter.orderBy,
        categoryId: filter.categoryId,
        transmission: filter.transmission,
        seats: filter.seats,
        fuel: filter.fuel,
        address: filter.address,
      });

      await requestFilterCar
        .runAsync(query)
        .then((res: any) => setCars(res.data));
      setIsShow(false);
      setIsFilter && setIsFilter(true);
    } catch (error) {
      console.error("Failed to fetch cars:", error);
      setIsFilter && setIsFilter(false);
    }
  };

  return (
    <div>
      <div
        className={`flex items-center justify-center p-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-700 focus:outline-none cursor-pointer`}
        onClick={() => setIsShow(!isShow)}
      >
        <AdjustmentsVerticalIcon className="w-6 h-6" />
        {/* {renderXClear()} */}
      </div>

      <Transition appear show={isShow} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={() => setIsShow(false)}
        >
          <div className="min-h-screen text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              className="inline-block py-8 px-4 md:py-20 md:px-8 h-screen w-full max-w-4xl"
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6"
                  >
                    Tìm kiếm xe
                  </Dialog.Title>
                  <span className="absolute left-3 top-3">
                    <ButtonClose onClick={() => setIsShow(false)} />
                  </span>
                </div>

                <div className="px-6 py-4 h-full overflow-auto space-y-3">
                  <div className="w-full">
                    <span className="font-semibold text-lg text-textPrimary dark:text-neutral-200">
                      Địa điểm
                    </span>
                    <div className="w-full">
                      <Listbox
                        value={filter?.address?.province?.id}
                        onChange={(e) =>
                          setFilter((prev) => ({
                            ...prev,
                            address: {
                              province: JSON.parse(e.toString()),
                            },
                          }))
                        }
                      >
                        <div className="relative mt-1">
                          <Listbox.Button className="relative w-full cursor-default rounded-lg  py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-colorPrimary sm:text-sm">
                            <span className="block truncate">
                              {filter?.address?.province?.title ||
                                "Chọn địa điểm"}
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="z-50 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-colorPrimary text-colorPrimary dark:text-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                              {place.map((data, index) => (
                                <Listbox.Option
                                  key={index}
                                  className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                      active
                                        ? "bg-amber-100 text-colorSecondary/50"
                                        : "text-text-white"
                                    }`
                                  }
                                  value={JSON.stringify({
                                    id: data?.province_id,
                                    title: data?.title,
                                  })}
                                >
                                  {({ selected }) => (
                                    <>
                                      <span
                                        className={`block truncate ${
                                          selected
                                            ? "font-medium"
                                            : "font-normal"
                                        }`}
                                      >
                                        {data?.title}
                                      </span>
                                      {selected ? (
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                    </div>
                  </div>
                  <div className="w-full">
                    <span className="font-semibold text-lg text-textPrimary dark:text-neutral-200">
                      Khoảng giá
                    </span>
                    <div className="relative flex flex-col p-3 space-y-3">
                      <Slider
                        range
                        min={0}
                        max={50000000}
                        step={100000}
                        defaultValue={[
                          filter.rangePrice.lower,
                          filter.rangePrice.upper,
                        ]}
                        allowCross={false}
                        onChange={(e) =>
                          setFilter((prev) => ({
                            ...prev,
                            rangePrice: {
                              lower: e[0] as number,
                              upper: e[1] as number,
                            },
                          }))
                        }
                      />

                      <div className="flex justify-between space-x-5">
                        <div className="mt-1 relative rounded-md">
                          <input
                            type="text"
                            name="minPrice"
                            disabled
                            id="minPrice"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full text-center sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={formatCurrency(filter.rangePrice.lower)}
                          />
                        </div>
                        <div className="mt-1 relative rounded-md">
                          <input
                            type="text"
                            disabled
                            name="maxPrice"
                            id="maxPrice"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full text-center sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={formatCurrency(filter.rangePrice.upper)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="text-[#E1E1E2] mt-3 w-full" />
                  <div className="w-full">
                    <span className="font-semibold text-lg text-textPrimary dark:text-neutral-200">
                      Năm sản xuất
                    </span>
                    <div className="relative flex flex-col p-3 space-y-3">
                      <Slider
                        range
                        min={1945}
                        max={2300}
                        defaultValue={[
                          filter.rangeYear.lower,
                          filter.rangeYear.upper,
                        ]}
                        allowCross={false}
                        onChange={(e) =>
                          setFilter((prev) => ({
                            ...prev,
                            rangeYear: {
                              lower: e[0] as number,
                              upper: e[1] as number,
                            },
                          }))
                        }
                      />

                      <div className="flex justify-between space-x-5">
                        <div className="mt-1 relative rounded-md">
                          <input
                            type="text"
                            name="minYear"
                            disabled
                            id="minYear"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full  text-center sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={filter.rangeYear.lower}
                          />
                        </div>
                        <div className="mt-1 relative rounded-md">
                          <input
                            type="text"
                            disabled
                            name="maxYear"
                            id="maxYear"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full text-center sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={filter.rangeYear.upper}
                          />
                        </div>
                      </div>
                    </div>

                    <hr className="text-[#E1E1E2] mt-3 w-full" />
                  </div>
                  <div>
                    <span className="font-semibold text-lg text-textPrimary dark:text-neutral-200">
                      Sắp xếp theo
                    </span>
                    <div className="mt-[15px] flex flex-row items-center  w-full gap-5 flex-wrap">
                      {filterPrice.map((item, index) => (
                        <Radio
                          key={index}
                          id={item.orderBy}
                          name={"filterPrice"}
                          label={item.title}
                          checked={filter.orderBy === item.orderBy}
                          onChange={(e) =>
                            setFilter((prev) => ({
                              ...prev,
                              orderBy: e,
                            }))
                          }
                        />
                      ))}
                    </div>
                  </div>
                  <hr className="text-[#E1E1E2] mt-3 w-full" />
                  <div>
                    <span className="font-semibold text-lg text-textPrimary dark:text-neutral-200">
                      Hãng xe
                    </span>
                    <div className="mt-[15px] flex flex-row items-center w-full gap-5 flex-wrap">
                      {serviceCategory?.map((item, index) => (
                        <Radio
                          key={index}
                          id={item?.xid}
                          checked={filter.categoryId === item?.xid}
                          label={item?.name}
                          name={"serviceCategory"}
                          // image={item?.image_url}
                          onChange={(e) =>
                            setFilter((prev) => ({
                              ...prev,
                              categoryId: e,
                            }))
                          }
                        />
                      ))}
                    </div>
                  </div>
                  <hr className="text-[#E1E1E2] mt-3 w-full" />
                  <div>
                    <span className="font-semibold text-lg text-textPrimary dark:text-neutral-200">
                      Truyền động
                    </span>
                    <div className="mt-[15px] flex flex-row items-center  w-full gap-5 flex-wrap">
                      {filterTransmission.map((item, index) => (
                        <Radio
                          key={index}
                          id={item.value}
                          name={"filterTransmission"}
                          label={item.title}
                          checked={filter.transmission === item.value}
                          onChange={(e) =>
                            setFilter((prev) => ({
                              ...prev,
                              transmission: e,
                            }))
                          }
                        />
                      ))}
                    </div>
                  </div>
                  <hr className="text-[#E1E1E2] mt-3 w-full" />
                  <div>
                    <span className="font-semibold text-lg text-textPrimary dark:text-neutral-200">
                      Loại nhiên liệu
                    </span>
                    <div className="mt-[15px] flex flex-row items-center  w-full gap-5 flex-wrap">
                      {filterFuel.map((item, index) => (
                        <Radio
                          key={index}
                          id={item.value}
                          name={"filterFuel"}
                          label={item.title}
                          checked={filter.fuel === item.value}
                          onChange={(e) =>
                            setFilter((prev) => ({
                              ...prev,
                              fuel: e,
                            }))
                          }
                        />
                      ))}
                    </div>
                  </div>
                  <div>
                    <span className="font-semibold text-lg text-textPrimary dark:text-neutral-200">
                      Chỗ ngồi
                    </span>
                    <div className="mt-[15px] flex flex-row items-center  w-full gap-5 flex-wrap">
                      {filterSeats.map((item, index) => (
                        <Radio
                          key={index}
                          id={item.value}
                          label={item.title}
                          checked={filter.seats === item.value}
                          name={"filterSeats"}
                          onChange={(e) =>
                            setFilter((prev) => ({
                              ...prev,
                              seats: e,
                            }))
                          }
                        />
                      ))}
                    </div>
                  </div>
                </div>

                <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                  <ButtonThird
                    onClick={handleResetFilter}
                    sizeClass="px-4 py-2 sm:px-5"
                  >
                    Xóa tất cả
                  </ButtonThird>
                  <ButtonPrimary
                    onClick={handleFilter}
                    sizeClass="px-4 py-2 sm:px-5"
                    loading={requestFilterCar.loading}
                  >
                    Lọc
                  </ButtonPrimary>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default ModalFilterMobile;
